import React, { useEffect } from 'react'

// TODO: 404 page must redirect to not found
export default function NotFound() {
  useEffect(() => {
    window.location.href = '/not-found'
  }, [])

  return <></>
}
